<template>
    <div>
        <div class="t-page" v-show="isSuccess == false">
            <div class="banner">
                <img src="https://resources.holowits.com.sg/HC/invi-banner0821.jpg" alt="">
                <p style="color: #000000; font-size: 11px;">Registration will close when the number of registrants reaches the maximum. Please register as soon as possible.</p>
                <p style="color: #000000; font-size: 11px;">One registeration for one person. Thank you for your cooperation.</p>
            </div>
            <div class="title first">
                How can we reach you?
            </div>
            <div class="t-input" :class="{'error': rules.fullName}">
                <input class="el-input__inner" v-model="form.fullName"/>
                <div :class="['t-label', form.fullName != ''? 'focus': null]">Full Name (Please input English)</div>
            </div>
            <div class="t-input">
                <el-select v-model="form.jobTitle" filterable placeholder="Job Title" style="width: 100%;" @focus="activity1 = true" @blur="activity1 = false">
                    <el-option label="CEO" value="CEO"></el-option>
                    <el-option label="CIO/IT Manager" value="CIO/IT Manager"></el-option>
                    <el-option label="Marketing Director/Manager" value="Marketing Director/Manager"></el-option>
                    <el-option label="Sales Director/Manager" value="Sales Director/Manager"></el-option>
                    <el-option label="Technical Director/Manager" value="Technical Director/Manager"></el-option>
                    <el-option label="Engineering/Technical Staff" value="Engineering/Technical Staff"></el-option>
                    <el-option label="Other" value="Other"></el-option>
                </el-select>
                <div :class="['t-label', 'optional', form.jobTitle != ''? 'focus': null, activity1 ? 'focus2': null]">Job Title</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.email"/>
                <div :class="['t-label', 'optional', form.email != ''? 'focus': null]">Email</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.telephone"/>
                <div :class="['t-label', 'optional', form.telephone != ''? 'focus': null]">Telephone</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.whatsappId"/>
                <div :class="['t-label', 'optional', form.whatsappId != ''? 'focus': null]">Whatsapp ID</div>
            </div>
            <div class="title first">
                Company Details
            </div>
            <div class="t-input" :class="{'error': rules.companyName}">
                <input class="el-input__inner" v-model="form.companyName"/>
                <div :class="['t-label', form.companyName != ''? 'focus': null]">Company Name</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.businessType"/>
                <div :class="['t-label', 'optional', form.businessType != ''? 'focus': null]">Business Type</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.industry"/>
                <div :class="['t-label', 'optional', form.industry != ''? 'focus': null]">Industry</div>
            </div>
            <div class="title first">
                Choose business activities you will participate in
            </div>
            <div class="t-input" :class="{'error': rules.launchEvent}">
                <el-select id="selector1" v-model="form.launchEvent" filterable placeholder="Launch Even" style="width: 100%;" @focus="activity3 = true" @blur="activity3 = false">
                    <el-option label="Not Participate" value="Not Participate"></el-option>
                    <el-option label="September 20, 14:00-16:20" value="September 20, 14:00-16:20"></el-option>
                </el-select>
                <div :class="['t-label', form.launchEvent != ''? 'focus': null, activity3 ? 'focus2': null]">HOLOWITS Autumn 2024 New Product Launch</div>
            </div>
            <div class="t-input" :class="{'error': rules.roundtableMeeting}">
                <el-select v-model="form.roundtableMeeting" filterable placeholder="Partner Roundtable Meeting" style="width: 100%;" @focus="activity4 = true" @blur="activity4 = false">
                    <el-option label="Not Participate" value="Not Participate"></el-option>
                    <el-option label="September 20, 17:00-18:30" value="September 20, 17:00-18:30"></el-option>
                </el-select>
                <div :class="['t-label', form.roundtableMeeting != ''? 'focus': null, activity4 ? 'focus2': null]">Partner Roundtable Meeting (1-2 CXO Level Participants from 1 Company)</div>
            </div>
            <div class="t-input" :class="{'error': rules.banquet}">
                <el-select v-model="form.banquet" filterable placeholder="Banquet" style="width: 100%;" @focus="activity5 = true" @blur="activity5 = false">
                    <el-option label="Not Participate" value="Not Participate"></el-option>
                    <el-option label="September 20, 18:30-20:30" value="September 20, 18:30-20:30"></el-option>
                </el-select>
                <div :class="['t-label', form.banquet != ''? 'focus': null, activity5 ? 'focus2': null]">Banquet (1-2 CXO Level Participants from 1 Company)</div>
            </div>
            <div class="t-input" :class="{'error': rules.exhibitionTime}">
                <el-select v-model="form.exhibitionTime" filterable placeholder="Exhibition Session" style="width: 100%;" @focus="activity2 = true" @blur="activity2 = false">
                    <el-option label="Not Participate" value="Not Participate"></el-option>
                    <el-option label="September 19, 09:00-10:00" value="September 19, 09:00-10:00"></el-option>
                    <el-option label="September 19, 10:00-11:00" value="September 19, 10:00-11:00"></el-option>
                    <el-option label="September 19, 11:00-12:00" value="September 19, 11:00-12:00"></el-option>
                    <el-option label="September 19, 12:00-13:00" value="September 19, 12:00-13:00"></el-option>
                    <el-option label="September 19, 13:00-14:00" value="September 19, 13:00-14:00"></el-option>
                    <el-option label="September 19, 14:00-15:00" value="September 19, 14:00-15:00"></el-option>
                    <el-option label="September 19, 15:00-16:00" value="September 19, 15:00-16:00"></el-option>
                    <el-option label="September 19, 16:00-17:00" value="September 19, 16:00-17:00"></el-option>
                    <el-option label="September 19, 17:00-18:00" value="September 19, 17:00-18:00"></el-option>
                    <el-option label="September 20, 09:00-10:00" value="September 20, 09:00-10:00"></el-option>
                    <el-option label="September 20, 10:00-11:00" value="September 20, 10:00-11:00"></el-option>
                    <el-option label="September 20, 11:00-12:00" value="September 20, 11:00-12:00"></el-option>
                    <el-option label="September 20, 12:00-13:00" value="September 20, 12:00-13:00"></el-option>
                    <el-option label="September 20, 13:00-14:00" value="September 20, 13:00-14:00"></el-option>
                    <el-option label="September 20, 14:00-15:00" value="September 20, 14:00-15:00"></el-option>
                    <el-option label="September 20, 15:00-16:00" value="September 20, 15:00-16:00"></el-option>
                    <el-option label="September 20, 16:00-17:00" value="September 20, 16:00-17:00"></el-option>
                    <el-option label="September 20, 17:00-18:00" value="September 20, 17:00-18:00"></el-option>

                </el-select>
                <div :class="['t-label', form.exhibitionTime != ''? 'focus': null, activity2 ? 'focus2': null]">Exhibition Session</div>
            </div>

            <div class="title first">
                Do you have any feedback or comments on future cooperation with HOLOWITS?
            </div>
            <div class="t-input">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="form.difficulty"></el-input>
            </div>
            <div class="t-input" :class="{'error': rules.authorization}">
                <input type="checkbox" v-model="form.authorization" style="margin-right: 10px;"/><i style="color: red; padding-right: 5px;">*</i>
                <span>To promote future cooperation and provide you with better services, HOLOWITS and its distributors may retain your information.</span>
                <!-- <div v-show="!form.authorization" class="error-info">This field is required.</div> -->
            </div>
            
            <div class="t-input" style="display: flex; justify-content: center;">
                <el-button round style="background-color: #c7000b; color: #ffffff; width: 160px;" :loading="isLoading" @click="add">Submit</el-button>
            </div>
        </div>
        <div v-show="isSuccess === true" style="text-align: center;">
            <img src="https://resources.holowits.com.sg/HC/partner0821.jpg" alt="">
        </div>
    </div>
</template>
  
<script>
  import countrys from '@/utils/country.json';
  import {save} from '@/api/otherFormApi';
  export default {
    name: 'ShanghaiInternalIndex',
  data() {
      return {
          isSuccess: false,
          language: 'en',
          activity1: false,
          activity2: false,
          activity3: false,
          activity4: false,
          activity5: false,
          form: {
            fullName: '',
            jobTitle: '',
            email: '',
            telephone: '',
            whatsappId: '',
            companyName: '',
            businessType: '',
            industry: '',
            exhibitionTime: '',
            launchEvent: '',
            roundtableMeeting: '',
            banquet: '',
            difficulty: '',
            authorization: false
          },
          rules: {
            fullName: false,
            companyName: false,
            launchEvent: false,
            roundtableMeeting: false,
            banquet: false,
            exhibitionTime: false,
            authorization: false
          },
          countryArr: [],
          isLoading: false,
          hasError: false
      }
  },
  created() {
      this.countryArr = countrys.country.map(item => {
          return {label: item, value: item}
      })
  },
  watch: {
    form: {
        handler(n, v) {
            let checkList = Object.keys(n)
            for (let key of checkList) {
                this.check(key, n[key])
            }
        },
        deep: true
    }
  },
  methods: {
    check(key, value) {
        if (key in this.rules) {
            return this.rules[key] = value ? false : true;
        }
    },
     async add() {
        this.form['source'] = 'shanghai-partner'
        let checkList = Object.keys(this.rules)
        let hasError = false
        for (let key of checkList) {
            if (this.check(key, this.form[key])) {
                hasError = true
            }
        }
        if (hasError) {
            return false;
        }

          this.isLoading = true
          try {
            const {data: {data, isSuccess}} = await save({
                content: JSON.stringify(this.form),
                pageSource: 'shanghai-partner'
            })
            if (isSuccess) {
                this.isSuccess = true
                this.isLoading = false
            } else {
                this.isLoading = false
            }
          } catch(e) {
            this.isLoading = false
          }
          
    }
  }
  }
</script>
  
<style lang="scss" scoped>
  .t-page {
    padding-bottom: 30px;
  }
  .banner {
      width: 100%;
      img {
          width: 100%;
      }
  }
  .error-info {
      color: red;
      font-size: 12px;
  }
  .company-icon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  
      .left {
          width: 163px;
          height: 80px;
      }
      .line {
          height: 40px;
          width: 2px;
          background-color: #000000;
      }
      .right {
          width: 105px;
          height: 105px;
      }
  }
  .first {
      margin-top: 20px;
  }
  
  .title {
      font-size: 18px;;
      font-weight: 600;
      margin-bottom: 20px;
  }
  .require {
    &::after {
        content: '*';
        padding-left: 2px;
        color: #c7000b;
    }
  }
  .t-input {
      margin-bottom: 20px;
      position: relative;
  
      .t-label {
          position: absolute;
          font-size: 14px;
          top: 10px;
          left: 16px;
          background-color: #ffffff;
          color: #C0C4CC;
          transition: all 0.4s;
  
          &::after {
              content: '*';
              padding-left: 2px;
              color: #c7000b;
          }
      }
      .optional {
          &::after {
              content: '';
          }
      }
      .focus {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
      .focus2 {
          padding: 0 5px;
          top: -12px;
          left: 12px;
          font-size: 12px;
      }
      .el-input__inner:focus+.t-label {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
  }
  .error {
   ::v-deep input {
        border-color: rgb(199, 0, 11) !important;
    }
    span {
        color: #c7000b;
    }
  }
  @media only screen and (min-width: 992px) {
      .t-page {
          max-width: 838px;
          margin: 20px auto;
      }
  }
  @media only screen and (max-width: 991px) {
      .t-page {
          width: 100%;
          margin: 20px auto;
          padding: 15px;
      }
  }
</style>